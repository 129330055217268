
html, body {
  font-size: 14px;
  line-height: 16px;
  font-family: 'Roboto' !important;
  font-style: normal;
  background: #FDFEFF;
}

.App {
  margin: 0;
  height: 100vh;
}

.header {
  position: absolute;
  width: 100%;
  height: 77;
  left: 0;
  top: 0;
  background: #FFFFFF !important;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  padding: 0 !important;
}

.header-logo {
  margin-left: 20%;
  height: 50px;
  cursor: pointer;
}

.footer {
  background-color: white !important;
  margin-top: 40px;
}

.layout {
  text-align: center;
  text-align: "-webkit-center";
  margin: 0 20%;
  margin-top: 107px;
  background: white;
}

.map-container {
  height: 50vh;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.description-container {
  border-radius: 5px;
  padding: 15px 20px 10px;
  margin-top: 11px;
}

.description-container .ant-card-body {
  padding: 0;
}

.fleet-name {
  color: #4A525F;
  font-family: Inter;
  font-weight: 700;
  font-size: 18px;
}

.trip-not-available {
  display: flex;
  align-items: center;
  height: 50vh;
  width: 100%;
  border-radius: 20px;
  background: rgba(215, 220, 229, 0.2);
  border: 1px solid rgba(215, 220, 229, 0.5);
}

.trip-not-available-text {
  width: 100%;
  text-align: center;
  color: #4A525F;
  margin: 10px;
}

.form-select .ant-select-selector {
  border-radius: 5px !important;
  width: 100%;
  color: #4A525F !important;
}

.form-select .ant-select-selection-item {
  font-weight: 500;
}

.ant-select-dropdown .ant-select-item-option {
  color: #4A525F !important;
  font-weight: 500;
}

.form-time-picker .ant-input {
  cursor: pointer;
  color: #4A525F;
  font-weight: 500;
}

.form-time-picker .ant-input:disabled {
  cursor: not-allowed;
}

.form-date-picker .ant-picker-input>input {
  font-family: inherit;
  color: #4A525F;
  font-weight: 500;
}

.form-title {
  display: block;
  color: #9398A0;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 6px;
}

.fleet-title {
  text-align: center;
  color: #4A525F;
  font-family: Roboto;
  margin-bottom: 40px;
}

.ant-modal .ant-modal-content {
  padding: 20px;
}

.modal-component .ant-card-body {
  padding: 50px 30px;
}

.ampm-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  background: rgba(215, 220, 229, 0.5);
  border-radius: 5px;
  height: 42px;
  margin-left: 20px;
  padding: 5px;
}

.ampm-component {
  height: 100%;
  border-radius: 4px;
  font-size: 20px;
  line-height: 23px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.time-input-component {
  color: #4A525F;
  width: 88px;
  height: 52px;
  font-size: 32px;
  background: rgba(215, 220, 229, 0.2);
  border-color: rgba(215, 220, 229, 0.2);
  font-weight: 400;
  line-height: 38px;
  border-radius: 5px;
  text-align: center;
}

.time-input-component:focus-within {
  background: white;
}

.time-input-label {
  color: #9398A0;
  font-size: 16px;
  transform: none;
  font-weight: 400;
  line-height: 19px;
  margin-top: 5;
}

.modal-button-container {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.form-input {
  width: "100%";
  border-radius: 5;
}

.ant-card-head-title {
  text-overflow: unset !important;
  white-space: unset !important;
}

@media (max-width: 680px) {
  .layout {
    text-align: center;
    text-align: "-webkit-center";
    margin: 0 5%;
    margin-top: 107px;
    background: white;
  }

  .header-logo {
    margin-left: 5%;
    height: 50px;
  }
}

@media (max-width: 480px) {
  .layout {
    text-align: center;
    text-align: "-webkit-center";
    margin: 0 5%;
    margin-top: 107px;
    background: white;
  }

  .header-logo {
    margin-left: 5%;
    height: 50px;
  }

  .ampm-component {
    padding: 0 7px;
  }

  .time-input-component {
    width: 55px;
  }

  .ampm-container {
    margin-left: 10px;
  }
}